import { BaseEntityModel } from '@common-src/model/base-model';
import { FormControl, FormControlType, FormControlTextModel, FormControlOptionModel } from '@common-src/model/form-control';
import { QueryPageModel } from '@common-src/model/query-model';
import { QueryControl, QueryControlType } from '@common-src/model/query-control';
import DutyShiftService from '@common-src/service/duty-shift';
import { dateFormat } from '@common-src/filter';

export class DutyManagementEntityModel extends BaseEntityModel {
    @FormControl({
        label: '值班组名称',
        type: FormControlType.TEXT,
        required: true
    } as FormControlTextModel)
    departmentName: string = undefined;

    @FormControl({
        label: '班次',
        type: FormControlType.SELECT,
        mode: 'multiple',
        optionsPromise: DutyShiftService.getAll,
        required: true
    } as FormControlOptionModel)
    shiftIds: Array<string> = [];

    @FormControl({
        label: '值班人员',
        type: FormControlType.SELECT_USER,
        mode: 'multiple',
        required: true
    } as FormControlOptionModel)
    userIds: Array<string> = [];
    groupIds:Array<string> = [];

    num: string = undefined;
    shiftNames: Array<string> = [];
    creator: string = undefined;
    updateDate: string = undefined;

    static getTableColumns() {
        return [
            {
                title: '值班组名称',
                dataIndex: 'departmentName',
                width: 400,
                scopedSlots: { customRender: 'name' }
            },
            {
                title: '人数',
                dataIndex: 'num',
                customerRender: (text) => {
                    return text + '人';
                }
            }, {
                title: '班次',
                dataIndex: 'shiftNames',
                customRender: (text, record) => {
                    return record.shiftNames.join('、');
                }
            }, {
                title: '创建人',
                dataIndex: 'creator'
            }, {
                title: '更新时间',
                dataIndex: 'updateDate',
                customRender: (text) => {
                    return dateFormat(text);
                }
            },
            {
                title: '操作',
                dataIndex: 'action',
                scopedSlots: { customRender: 'action' }
            }
        ];
    }

    toModel(json): any {
        super.toModel(json);

        const users = this.userIds;
        const groups = this.groupIds;
        const userNames = _.get(json, 'userNames');
        const groupNames = _.get(json, 'groupNames');

        let mixins = [];
        if (users && users.length > 0) {
            users.forEach((user, userIndex) => {
                mixins.push({ id: user, isUser: true, name: userNames[userIndex] });
            });
        }
        if (groups && groups.length > 0) {
            groups.forEach((group, groupIndex) => {
                mixins.push({ id: group, isUser: false, name: groupNames[groupIndex] });
            });
        }

        this.userIds = mixins;
        return this;
    }

    toService() {
        const data: any = super.toService();
        const users = [];
        const groups = [];
        if (data.userIds && data.userIds.length > 0) {
            data.userIds.forEach(user => {
                if (user.isUser) {
                    users.push(user.id);
                } else {
                    groups.push(user.id);
                }
            });
        }
        data.userIds = [];
        data.userIds = users;
        data.groupIds = groups;
        return data;
    }
}

export class DutyManagementQueryModel extends QueryPageModel {
    @QueryControl({
        label: '名称',
        type: QueryControlType.TEXT
    })
    name: string = undefined;

    toService() {
        const data: any = super.toService();
        return data;
    }
}
