
import { DutyManagementEntityModel, DutyManagementQueryModel } from '@common-src/entity-model/duty-management-entity';
import { ICRUDQ } from '@common-src/model/interface';
import { get, post, put, del } from './request';

const URL_PATH = `${ASSET_BASE_REQUEST_PATH}/dutyRoster`;

class DutyManagementService implements ICRUDQ<DutyManagementEntityModel, DutyManagementQueryModel> {
    async create(model: DutyManagementEntityModel):Promise<DutyManagementEntityModel> {
        const url = `${URL_PATH}/add`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    async retrieve(modelId: string):Promise<DutyManagementEntityModel> {
        const url = `${URL_PATH}/detail/${modelId}`;
        const res = await get(url);
        return new DutyManagementEntityModel().toModel(res);
    }

    async update(model: DutyManagementEntityModel):Promise<DutyManagementEntityModel> {
        const url = `${URL_PATH}/update`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    async delete(model: DutyManagementEntityModel):Promise<DutyManagementEntityModel> {
        const url = `${URL_PATH}/${model.id}`;
        const res = await del(url);
        return res;
    }

    async query(query?: DutyManagementQueryModel, page?: number, limit?: number):Promise<any> {
        const url = `${URL_PATH}/list`;
        const params = Object.assign({ page, limit }, query?.toService());
        const res = await post(url, params);
        res.items = _.map(res.items, item => item = new DutyManagementEntityModel().toModel(item));
        return res;
        // 不带分页功能的处理方法
        // return _.map(res, item => item = new DutyManagementEntityModel().toModel(item));
    }

    async design(id:string, month:string):Promise<any> {
        const url = `${ASSET_BASE_REQUEST_PATH}/employeeDuty/${id}/${month}/list`;
        const res = await get(url);
        return res;
    }

    async saveDesign(id:string, month:string, data:any):Promise<any> {
        const url = `${ASSET_BASE_REQUEST_PATH}/employeeDuty/${id}/${month}/update`;
        const params = data;
        const res = await put(url, params);
        return res;
    }

    async getDutyCalendar(month: string, dutyRosterId:string): Promise<any> {
        const url = `${ASSET_BASE_REQUEST_PATH}/employeeDuty/itemsByMonth`;
        const params = { dutyRosterId: dutyRosterId, month: month };
        const res = await post(url, params);
        return res;
    }

    async getMyDutyCalendar(month: string): Promise<any> {
        const url = `${ASSET_BASE_REQUEST_PATH}/employeeDuty/${month}/myDuty`;
        const res = await get(url);
        return res;
    }
}

export default new DutyManagementService();
